import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { tokenLogin } from '../../utility/firebase';

import { useUtilityContext } from '../../utility-provider';
import { useUserContext } from '../../user-provider';
import { searchReceptionBook } from '../../api/DataManage';
import { setReceptionBookList, setReceptionBook } from '../../features/receptionBookSlice';

import {
    Box,
    Select,
    MenuItem,
} from "@mui/material";


export interface selectPlanProps {
    props: {
    }
}


const SelectPlan: React.FC<selectPlanProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();
    const user = useUserContext();

    const dispatch = useAppDispatch();
    const receptionBookList = useAppSelector((state) => state.receptionBook.list);
    const receptionBook = useAppSelector((state) => state.receptionBook.select);
    const [value, setValue] = React.useState<string>("")

    const [loadApi, setLoadApi] = React.useState<boolean>(false);
    const [userData, setUserData] = React.useState<any>({});

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        const sync = async () => {
            await tokenLogin(window.location.href)
                .then((val: any) => {
                    if (val === 'unprocessed') {
                        // 未処理の場合はバックドロップ消さない
                        return;
                    }
                    if (user.setUserData) {
                        user.setUserData(val as string);
                    }
                }).catch((e: any) => {
                    console.log(e)
                })
        }
        sync();
        if (user.userData !== '') {
            setUserData(JSON.parse(user.userData));
            setLoadApi(true)
        }
    }, [user]);

    React.useEffect(() => {
        if (loadApi) {
            getReceptionBookList()
        }
    }, [loadApi])

    const getReceptionBookList = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        try {
            callApi('searchReceptionBook').then((res: any) => {
                if (res.searchReceptionBook.length > 0) {
                    const tmpIdList: any = []
                    const tmpList: any = []
                    res.searchReceptionBook.map((row: any) => {
                        if (!tmpIdList.includes(row.plan_area_id)) {
                            tmpIdList.push(row.plan_area_id)
                            tmpList.push(row)
                        }
                    })
                    dispatch(setReceptionBookList(tmpList))
                }
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setLoadApi(false)
            }).catch((e) => {
                console.log(e)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setLoadApi(false)
            })
        } catch (e) {
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            setLoadApi(false)
        }
    }

    const callApi = async (target: string) => {
        if (target === 'searchReceptionBook') {
            return await searchReceptionBook({
                mode: "searchReceptionBook",
                plan_area_id: '',
            })
        }
    }

    const selectHandler = (event: any) => {
        setValue(event.target.value)
        receptionBookList.map((row: any) => {
            if (row.plan_area_id === event.target.value) {
                dispatch(setReceptionBook(row))
            }
        })
    }

    React.useEffect(() => {
        if (receptionBook.plan_area_id !== "") {
            setValue(receptionBook.plan_area_id)
        }
    }, [receptionBook])

    return (
        <Box>
            <Box sx={{ margin: '10px 10px' }}>候補地No</Box>
            <Box sx={{ width: '100%' }}>
                <Select
                    sx={{ width: '100%' }}
                    value={value}
                    onChange={(event) => selectHandler(event)}
                >
                    {receptionBookList?.map((row: any) => (
                        <MenuItem
                            key={row.plan_area_id}
                            value={row.plan_area_id}>
                            {'候補地No：' + row.plan_area_id}</MenuItem>
                    ))}
                </Select>
            </Box>
        </Box>
    )
}

export default SelectPlan;