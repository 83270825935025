import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUtilityContext } from '../../utility-provider';
import { useUserContext } from '../../user-provider';
import { searchReceptionBook } from '../../api/DataManage';
import { tokenLogin } from '../../utility/firebase';

interface ModalProps {
    props: {
        modalOpen: boolean,
        setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
        planAreaId: string | null,
    }
};

interface dataTypeList {
    id: string,
    conversion_create_date: string,
    elapsedTime: string,
    type: string,
    department: string,
    district: string,
    rfc: string,
    plan_area_id: string,
    candidateSiteName: string,
    assembly: string,
    pattern: string,
    branch: string,
    status_id: string,
    pic_type: string,
    pic_name: string,
    task_id: string,
    reception_no: string
}

//TableCellのヘッダースタイル変更
const TableCellSx = {
    backgroundColor: "#00CC5B",
    color: "#fff",
};
//TableRowのカーソルをポインターへ変更
const TableRowSx = {
    cursor: "pointer"
};

const ResearchBranchModal: React.FC<ModalProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();
    const user = useUserContext();

    const [propsObj, setPropsObj] = useState<dataTypeList[]>();

    //画面遷移
    const handleRowClick = (branchType: string, task_id: string) => {
        let url = ""
        // if (process.env.REACT_APP_MODE !== "local" && process.env.REACT_APP_LOCATION_URL) {
        //     url = process.env.REACT_APP_LOCATION_URL
        // }
        // if (['D004', 'D005', 'D007', 'D008', 'D010'].includes(task_id)) {
        //     // 【立地調査リリース後対応】5m外、商圏カット承認用ページ
        //     url += `/approval-5mcut?planAreaId=${props.planAreaId}&branch=${branchType}`;
        // } else if (['E001', 'E010', 'E014', 'E012', 'E002', 'E003', 'E011', 'E015', 'E013'].includes(task_id)) {
        //     // 【立地調査リリース後対応】追加調査承認ページ
        //     url += `/approval-survey?planAreaId=${props.planAreaId}&branch=${branchType}`;
        // } else {
        //     url += `/LongPage?planAreaId=${props.planAreaId}&branch=${branchType}`
        // }
        //画面遷移
        url = process.env.REACT_APP_LOCATION_URL + `/LongPage?planAreaId=${props.planAreaId}&branch=${branchType}`
        window.open(url, '_blank')
    };

    const [userData, setUserData] = useState<any>({});

    //ユーザー取得タイミングによる画面制御
    useEffect(() => {
        const sync = async () => {
            await tokenLogin(window.location.href)
                .then((val: any) => {
                    if (val === 'unprocessed') {
                        // 未処理の場合はバックドロップ消さない
                        return;
                    }
                    if (user.setUserData) {
                        user.setUserData(val as string);
                    }
                }).catch((e: any) => {
                    console.log(e)
                })
        }
        sync();
        if (user.userData !== '') {
            setUserData(JSON.parse(user.userData));
        }
    }, [user]);

    //モーダルクローズ
    const handleResearchBranchClose = () => {
        props.setModalOpen(false);
    };

    useEffect(() => {
        if (props.planAreaId) {
            getReceptionBookList()
        }
    }, [props.planAreaId])

    const getReceptionBookList = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        try {
            callApi('searchReceptionBook').then((res: any) => {
                if (res && res.searchReceptionBook.length > 0) {
                    const tmpList: any = []
                    res.searchReceptionBook.map((row: any) => {
                        row.flagNo = setFlagNo(row.reception_no)
                        tmpList.push(row)
                    })
                    setPropsObj(tmpList)
                }
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }).catch((e) => {
                console.log(e)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            })
        } catch (e) {
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
        }
    }

    const setFlagNo = (receptionNo: string) => {
        let flagNo: string = '';
        if (receptionNo !== null) {
            if (window.location.href.indexOf('planareamenu') !== -1) {
                flagNo = receptionNo.slice(-2);
            } else if (window.location.href.indexOf('StoreMenu') !== -1) {
                flagNo = receptionNo.slice(0, 1);
            }
        }

        return flagNo;
    };


    const callApi = async (target: string) => {
        if (target === 'searchReceptionBook') {
            return await searchReceptionBook({
                mode: "searchReceptionBook",
                plan_area_id: props.planAreaId,
            })
        }
    }

    return (
        <Box>
            {/* 調査書枝選択画面モーダル */}
            <Dialog open={props.modalOpen} onClose={handleResearchBranchClose} fullWidth>
                <DialogTitle sx={{ textAlign: "center" }}>閲覧する枝を選択してください</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={TableCellSx}>ステータス</TableCell>
                                    <TableCell sx={TableCellSx}>更新日時</TableCell>
                                    <TableCell sx={TableCellSx}>フラグ</TableCell>
                                    <TableCell sx={TableCellSx}>枝</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {typeof propsObj !== "undefined" &&
                                    propsObj.map((value: any, index: any) => (
                                        <TableRow key={index} sx={TableRowSx} onClick={() => { handleRowClick(value.branch, value.task_id) }}>
                                            <TableCell>{value.status_id}</TableCell>
                                            <TableCell>{value.conversion_create_date}</TableCell>
                                            <TableCell>{value.flagNo}</TableCell>
                                            <TableCell>{value.branch}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default ResearchBranchModal;
